module.exports = {
    links: {
        binanceAffiliate: "https://binance.com/", 
        binanceNonAffiliate: "https://binance.com/", 
        bitsgapAffiliate: "https://bitsgap.com/", 
        quadencyAffiliate: "https://quadency.com/",
        pionexAffiliate: "https://www.pionex.com/",
        cryptohopperAffiliate: "https://www.cryptohopper.com/", 
        mudrexAffiliate: "https://mudrex.com/", 
        coinruleAffiliate: "https://coinrule.com/", 
        threecommasAffiliate: "https://3commas.io/", 
        tradesantaAffiliate: "https://tradesanta.com/en"
    }
}